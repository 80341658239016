import {
  ADD_WEALTH_WAITLIST,
  GET_ALL_GRIP_BONDS,
  GET_BOND_DETAILS,
  GET_GRIP_REDIRECTION_LINK,
  GET_ORDERS,
  GET_USER_CONSENT_STATUS,
  GET_WEALTH_WAITLIST,
  IS_BACK,
  REDEEM_NOW,
  REGISTER_USER_TO_GRIP,
  REGISTER_USER_TO_GRIP_SUCCESS,
  RESET_REDEEM,
  SET_BOND_DETAILS,
  SET_CURRENT_SCREEN,
  SET_ORDERS,
  SET_STASHFIN_APPROVE_STATUS,
  SET_REGISTERED_ON_GRIP,
  YUBI_REDIRECT,
  CLEAR_ERROR,
  PRIORITY_LIST_FORM_RESPONSES
} from "./actionTypes";

export const setCurrentScreen = (payload) => ({
  type: SET_CURRENT_SCREEN,
  payload
});

export const getUserConsentStatus = () => ({
  type: GET_USER_CONSENT_STATUS
});

export const setStashfinApproveStatus = (payload) => ({
  type: SET_STASHFIN_APPROVE_STATUS,
  payload
});

export const registerUserToGrip = (payload) => ({
  type: REGISTER_USER_TO_GRIP,
  payload
});

export const registerUserToGripSuccess = (payload) => ({
  type: REGISTER_USER_TO_GRIP_SUCCESS,
  payload
});

export const getAllGripBonds = () => ({
  type: GET_ALL_GRIP_BONDS
});

export const getBondDetails = (payload) => ({
  type: GET_BOND_DETAILS,
  payload
});

export const resetBondDetails = () => ({
  type: SET_BOND_DETAILS
});

export const getGripRedirectionLink = () => ({
  type: GET_GRIP_REDIRECTION_LINK
});

export const resetOrders = () => ({
  type: SET_ORDERS
});

export const getOrders = () => ({
  type: GET_ORDERS
});
export const redeemNow = (payload) => ({
  type: REDEEM_NOW,
  payload
});

export const resetWealthReferral = (payload) => ({
  type: RESET_REDEEM,
  payload
});

export const redirectToYubi = () => ({
  type: YUBI_REDIRECT
});

export const isBackToPartners = (payload) => ({
  type: IS_BACK,
  payload
});

export const getWealthWaitlist = () => ({
  type: GET_WEALTH_WAITLIST
});

export const addWealthWaitlist = (payload) => ({
  type: ADD_WEALTH_WAITLIST,
  payload
});

export const clearErrorAction = () => ({
  type: CLEAR_ERROR
});

export const setPriorityListFormResponses = (payload) => ({
  type: PRIORITY_LIST_FORM_RESPONSES,
  payload
});

export const setRegisteredOnGrip = (payload) => ({
  type: SET_REGISTERED_ON_GRIP,
  payload
});
